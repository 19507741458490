<template>
  <el-container :class="cls">
    <div class="imelink-groupmember__button" @click="changeShrink">
      <i :class="`el-icon-arrow-${shrink ? 'left' : 'right'}`" />
    </div>
    <el-header
      class="imelink-header"
      height="auto"
      :style="{
        opacity: shrink ? '0' : '1',
        marginLeft: shrink ? '-100px' : '0',
      }"
      >&nbsp;</el-header
    >
    <div
      class="voiceToText"
      :style="{
        opacity: shrink ? '0' : '1',
      }"
    >
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('voiceToText')"
        placement="top-start"
      >
        <div class="voiceIcon"></div>
      </el-tooltip>
      <el-switch
        v-model="speechConvertStatus"
        @change="changeSpeechConvertStatus"
      ></el-switch>
    </div>
    <div class="imelink-groupmember__view">
      <div class="optionInterface" v-if="control.isMemberOfThisGroup">
        <el-tooltip
          effect="dark"
          :content="$t('searchChatHistory')"
          placement="bottom"
          :visible-arrow="false"
          popper-class="optionInterfaceTooltip_imelinkGroup"
        >
          <div class="buttonStyle" @click.stop="openInnerSearch">
            <i class="iconfont_Me icon-search iconStyle"></i>
          </div>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :content="
            isGroupOwner
              ? $t('transferOwner')
              : $t('applyForGroupOwnerPermission')
          "
          placement="bottom"
          :visible-arrow="false"
          popper-class="optionInterfaceTooltip_imelinkGroup"
        >
          <div
            class="buttonStyle"
            style="margin-left: 14px"
            @click.stop="changeOwnerPermission"
          >
            <el-image
              style="width: 22px; height: 22px"
              :src="require('../../../assets/images/im/transfer_button.png')"
              :fit="'fill'"
            ></el-image>
          </div>
        </el-tooltip>
      </div>
      <div class="ownerBlock" v-if="groupOwner.id">
        <div class="line"></div>
        <imelink-contact
          :data="groupOwner"
          :group-owner-id="groupOwner.id"
          name-size="12px"
          avatar-size="30px"
          @click="_handleChangeMessage(groupOwner)"
        >
        </imelink-contact>
      </div>
      <div class="membersView">
        <imelink-contact
          v-for="(member, index) in groupInfo.members"
          :key="index"
          :data="member"
          :group-owner-id="groupInfo.ownerId"
          name-size="12px"
          avatar-size="30px"
          @click="_handleChangeMessage(member)"
          :transferringOwner="transferringOwner"
          :transferringOwnerSelect="member.transferringOwnerSelect"
          @selectNewGroupOwner="selectNewGroupOwner"
        >
          <!-- 群主删除群成员按钮 -->
          <template
            #action
            v-if="
              control.user.id == groupInfo.ownerId &&
                member.id !== control.user.id &&
                !transferringOwner
            "
          >
            <i
              class="el-icon-error"
              style="color:#F56C6C"
              @click.stop="_handleRemoveMember(member)"
            ></i>
          </template>
        </imelink-contact>
      </div>
      <div class="listFooter" v-if="transferringOwner">
        <div
          class="common"
          v-waves
          @click.stop="startTransfer"
          :class="{ confirmButton: newGroupOwner }"
          :style="{ cursor: !newGroupOwner ? 'not-allowed' : 'pointer' }"
        >
          <i class="iconfont_Me icon-check iconStyle"></i>
        </div>
        <div class="line"></div>
        <div class="common cancelButton" v-waves @click.stop="changeTransfer">
          <i class="iconfont_Me icon-x iconStyle"></i>
        </div>
      </div>
    </div>
    <el-footer
      class="imelink-footer imelink-groupmember__footer"
      v-show="!shrink"
      v-if="showFooter"
    >
      <i
        :title="$t('inviteFriend')"
        size="small"
        v-show="
          groupInfo.accessPattern != 7 || control.user.id === groupInfo.ownerId
        "
        class="el-icon--plus"
        @click="handleInvite"
      ></i>
      <i
        :title="isGroupOwner ? $t('deleteGroup') : $t('quitGroup')"
        size="small"
        :class="isGroupOwner ? 'iconfont_Me icon-delet' : 'el-icon--quit'"
        style="font-size:18px"
        v-if="!isCustomerServiceGroup"
        @click="handleExit"
      ></i>
      <i
        v-show="
          groupInfo.type == 3 ||
            (groupInfo.type == 1 && groupInfo.accessPattern == 1)
        "
        :title="$t('copyGroup')"
        size="small"
        class="el-icon-document-copy"
        v-if="!isCustomerServiceGroup"
        @click="handleCopy"
      ></i>
    </el-footer>
    <ApplyForOwnerPermission
      :dialogVisible="dialogVisible"
      @closeDialog="dialogVisible = false"
      :groupOwner="groupOwner"
      :groupInfo="control.currentContact"
    />
  </el-container>
</template>

<script>
import * as RESTApi from "@/api/rest";
import ImelinkContact from "./contact-item";
import { forcedHangUp } from "@/components/common/multimedia/coreOperations/local.js";
import ApplyForOwnerPermission from "./components/dialog/applyForOwnerPermission.vue";
export default {
  name: "GroupMemberView",
  components: {
    ImelinkContact,
    ApplyForOwnerPermission,
  },
  data() {
    return {
      shrink: false,
      showFooter: true,
      transferringOwner: false,
      newGroupOwner: null,
      dialogVisible: false,
    };
  },
  inject: ["control"],
  computed: {
    speechConvertStatus: {
      get() {
        return this.$store.state.imStore.otherPartyInfo.speechConvertStatus ===
          1
          ? true
          : false;
      },
      set() {},
    },
    cls() {
      return [
        "imelink-groupmember",
        { "imelink-groupmember--shrink": this.shrink },
      ];
    },
    groupOwner() {
      let groupInfo = _.cloneDeep(this.control.getCurrentGroupInfo());
      groupInfo.members.map((item) => {
        if (item.provider_state !== "" && item.provider_state.startsWith("2")) {
          item.isFrozen = true;
        } else {
          item.isFrozen = false;
        }
        return item;
      });
      const owner = groupInfo.members.filter(
        (item) => item.id === groupInfo.ownerId
      )[0];
      // 添加标签
      if (owner && this.userRemarks[owner.id]) {
        owner.remarks = this.userRemarks[owner.id].split(",");
      }
      return owner || {};
    },
    groupInfo() {
      let groupInfo = _.cloneDeep(this.control.getCurrentGroupInfo());
      groupInfo.members.map((item) => {
        if (!this.newGroupOwner) {
          item.transferringOwnerSelect = false;
        } else if (item.id == this.newGroupOwner.id) {
          item.transferringOwnerSelect = true;
        }
        if (item.provider_state !== "" && item.provider_state.startsWith("2")) {
          item.isFrozen = true;
        } else {
          item.isFrozen = false;
        }
        // 添加标签
        if (item && this.userRemarks[item.id]) {
          item.remarks = this.userRemarks[item.id].split(",");
        }
        return item;
      });
      groupInfo.members = groupInfo.members.filter(
        (item) => item.id !== groupInfo.ownerId
      );
      return groupInfo;
    },
    // 这是客服群
    isCustomerServiceGroup() {
      return (
        this.groupInfo.accessPattern == 5 || this.groupInfo.accessPattern == 6
      );
    },
    // 当前用户是否是群主
    isGroupOwner() {
      return this.groupInfo.ownerId === this.$store.getters.userInfo.id;
    },
    // 当前登录用户的标签信息
    userRemarks() {
      return this.$store.state.globalShare.userRemarks;
    },
  },
  watch: {
    groupInfo: {
      handler(val) {
        if (
          val &&
          val.accessPattern &&
          (val.accessPattern == 5 || val.accessPattern == 6) &&
          !this.isGroupOwner
        ) {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      },
      deep: true,
    },
    transferringOwner: {
      handler(val) {
        if (!val) {
          this.newGroupOwner = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    // 打开内部聊天搜索界面
    openInnerSearch() {
      this.$emit("openInnerSearch");
    },
    // 选择将被被转让群主的成员
    selectNewGroupOwner(member) {
      this.newGroupOwner = member;
    },
    // 转让群主
    changeOwnerPermission() {
      if (this.isGroupOwner) {
        this.changeTransfer();
      } else {
        this.dialogVisible = true;
      }
    },
    changeTransfer() {
      this.transferringOwner = !this.transferringOwner;
    },
    // 开始转让
    async startTransfer() {
      if (!this.newGroupOwner) {
        return;
      }
      await this.control.changeGroupOwner(this.newGroupOwner.id);
      this.changeTransfer();
    },
    // 切换语言转文字状态
    async changeSpeechConvertStatus() {
      let params = {
        targetType: this.$store.state.imStore.otherPartyInfo.isGroup
          ? "groupChat"
          : "chat",
        targetId: this.$store.state.imStore.otherPartyInfo.id,
        speechConvertStatus:
          this.$store.state.imStore.otherPartyInfo.speechConvertStatus === 1
            ? 0
            : 1,
      };
      let result = await RESTApi.settingChatState(params);
      if (result.code !== 200) {
        this.$message({
          type: "error",
          message: result.message,
        });
      } else {
        // 更新一下状态管理器的值,为避免直接改动污染数据，所以先深拷贝在存入管理器
        let otherPartyInfo = _.cloneDeep(
          this.$store.state.imStore.otherPartyInfo
        );
        otherPartyInfo.speechConvertStatus =
          result.data.data.speechConvertStatus;
        this.$store.commit("setOtherPartyInfo", otherPartyInfo);
      }
    },
    _handleChangeMessage(member) {
      if (this.control.selectionSource !== "joingroup") {
        if (this.control.searchMode) {
          this.control.searchMode = !this.control.searchMode;
        }
        this.control.tabChange("records", (data) => {
          this.control.addRecordContact(member);
          this.control.openMessage(member.id);
          return data;
        });
      }
    },
    _handleRemoveMember(contact) {
      this.messageBox({
        message: (
          <div>
            <i class="el-icon-delete" />
            <b>?</b>
          </div>
        ),
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            this.control.$emit(
              "remove-group-contact",
              contact.id,
              this.control.currentContact.id,
              {
                success: () => {
                  const { members } = this.groupInfo;
                  const index = members.findIndex(
                    (member) => member.id == contact.id
                  );
                  delete this.groupInfo.members[index];
                  this.$delete(this.groupInfo.members, index);
                },
              }
            );
          }
          done();
        },
      });
      return;
    },
    loading(vm, visible = true, loadingBtn = false) {
      const dom = vm.$el.querySelector(
        ".el-message-box__message [class^='el-icon-']"
      );
      if (!loadingBtn && dom) {
        if (visible) {
          dom.setAttribute("origin-class", dom.getAttribute("class"));
          dom.setAttribute("class", "el-icon-loading");
        } else {
          dom.setAttribute(
            "class",
            dom.getAttribute("origin-class") || "el-icon-document-copy"
          );
        }
        let btnDom = vm.$el.querySelector(".el-button--primary");
        if (btnDom) {
          btnDom.setAttribute(
            "class",
            "el-button el-button--default el-button--small el-button--primary el-icon-check"
          );
          btnDom.lastChild.setAttribute("class", "");
          btnDom.lastChild.setAttribute("style", "");
        }
      } else {
        let btnDom = vm.$el.querySelector(".el-icon-check");
        btnDom.setAttribute(
          "class",
          "el-button el-button--default el-button--small el-button--primary"
        );
        btnDom.lastChild.setAttribute("style", "display:inline-block");
        btnDom.lastChild.setAttribute("class", "el-icon-loading");
      }

      vm.$el.querySelectorAll(".el-button").forEach((node) => {
        node.style.pointerEvents = visible ? "none" : "auto";
      });
    },
    messageBox(options) {
      options = {
        ...options,
        ...{
          title: "",
          center: true,
          customClass: "imelink-delrecord-confirm",
          confirmButtonText: " ",
          cancelButtonText: " ",
          showClose: false,
          showCancelButton: true,
          //closeOnClickModal: false,
          cancelButtonClass: "el-icon-close",
          confirmButtonClass: "el-icon-check",
        },
      };
      this.$msgbox(options).catch(() => {});
    },
    handleInvite() {
      this.control.openSelection("joingroup");
    },
    changeShrink() {
      this.shrink = !this.shrink;
    },
    handleExit() {
      this.messageBox({
        message: (
          <div>
            <i class="el-icon-delete" />
            <b>?</b>
          </div>
        ),
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            this.loading(instance);
            forcedHangUp(this.control.currentContact.id).then((result) => {
              // 退出群组
              this.control.$emit(
                "exit-group",
                this.control.currentContact,
                this.groupInfo,
                {
                  success: () => {
                    this.loading(instance, false);
                    this.control.removeContact(this.control.currentContact.id);
                    done();
                  },
                  error: () => {
                    this.loading(instance, false);
                  },
                }
              );
            });
          } else {
            done();
          }
        },
      });
    },
    handleCopy() {
      this.messageBox({
        message: (
          <div>
            <i class="el-icon-document-copy" />
            <b>?</b>
          </div>
        ),
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            this.loading(instance, true, true);
            this.control.$emit(
              "copy-group",
              this.control.currentContact,
              _.cloneDeep(this.control.getCurrentGroupInfo()),
              {
                success: (contact) => {
                  this.loading(instance, false);
                  this.control.seamlessRefresh();
                  // 往记录列表中添加新的顶部数据
                  this.control.addContact(contact);
                  setTimeout(() => {
                    this.control.openMessage(contact.id);
                  }, 500);
                  done();
                },
                error: () => {
                  this.loading(instance, false);
                },
              }
            );
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.imelink-groupmember
  .imelink-header
    background #f9f9f9;
    border-bottom 1px solid #EEEEEE;
    border-radius 0px 5px 0px 0px;
  .voiceToText
    width 100%;
    display flex;
    flex-flow row;
    align-items center;
    padding 10px;
    justify-content space-between;
    transition: all ease 0.1s;
    .voiceIcon
      width 30px;
      height 30px;
      background url("../../../assets/images/4.3.0/voiceToText.png") no-repeat;
      background-size 100% 100%;
  .imelink-groupmember__view
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .optionInterface
      width: 100%;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 0 10px;
      .buttonStyle
        width: 30px;
        height: 30px;
        cursor: pointer;
        background: #E5E5E5;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .iconStyle
          font-size: 22px;
          color: #3E3E3E;
    .searchHistory
      width: 100%;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 12px 10px;
      cursor pointer;
      .title
        color: #000000;
        font-size: 14px;
      .iconStyle
        font-size: 18px;
        color: #DFDFDF;
    .line
      background: #EBEBEB;
      width: 100%;
      height: 1px;
    .ownerTitle
      color: #000000;
      font-size: 14px;
      padding: 12px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      cursor pointer;
      .iconStyle
        font-size: 18px;
        color: #DFDFDF;
    .ownerBlock
      overflow: hidden;
      color: #F9F9F9;
      flex-shrink: 0
      .line
        height: 4px;
    .membersView
      width: 100%;
      min-height: 0;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-light();
    .listFooter
      width: 100%;
      height: 40px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .common
        flex: 1;
        height: 40px;
        cursor pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #D9D9D9;
        .iconStyle
          font-size: 18px;
          color: #FFFFFF;
      .confirmButton
        background: #52BD68 !important;
      .line
        width: 1px;
        height: 100%;
        flex-shrink: 0;
        background: #FFFFFF;
      .cancelButton
        background: #A1A4A9 !important;
</style>
<style lang="stylus">
@import '~styles/utils/index';
.optionInterfaceTooltip_imelinkGroup
  padding 0 10px !important;
  font-size: 12px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
.loadingBtn
  animation:inherit
  &:before
    animation rotating 2s linear infinite !important
+b(imelink-groupmember)
  padding: 0;
  width: auto;
  flex: none;
  background: #f9f9f9;
  width: 180px;
  transition: all 0.3s ease;
  position: relative;
  +b(imelink-header)
    transition: all ease 0.5s;
  &:hover
    .imelink-groupmember__button
      display: block;
  +m(shrink)
    width: 0;
    .imelink-groupmember__button
      display: block;
  +e(view)
    overflow: hidden;
    border-left: 1px solid #f1f1f1;
    flex: 1;
    min-height: 0;
  +e(footer)
    border-left: 1px solid #f1f1f1;
    flex-center();
    height: 40px;
    padding: 14px 0px;
    flex-shrink: 0;
    > i
      font-size: 16px;
      cursor: pointer;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      margin: 0 5px;
      transition: all 0.3s ease-in-out;
      &:hover
        color: color-primary;
        background: lighten(color-primary, 40);
  .el-button--small
    font-weight: bold;
  +e(button)
    text-align: center;
    width: 18px;
    height: 100px;
    line-height: 100px;
    background: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    border-radius: 50px 0 0 50px;
    border-left: 1px solid #ddd;
    position-center-y();
    top: 40%;
    left: -18px;
    cursor: pointer;
    display: block;
    opacity: 0.5;
    &:hover
      background: #f1f1f1;
      opacity: 1;
  .imelink-contact
    padding: 6px 10px;
  .imelink-contact__name
    font-size: 12px;
</style>
