var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { class: _vm.cls },
    [
      _c(
        "div",
        {
          staticClass: "imelink-groupmember__button",
          on: { click: _vm.changeShrink }
        },
        [_c("i", { class: `el-icon-arrow-${_vm.shrink ? "left" : "right"}` })]
      ),
      _c(
        "el-header",
        {
          staticClass: "imelink-header",
          style: {
            opacity: _vm.shrink ? "0" : "1",
            marginLeft: _vm.shrink ? "-100px" : "0"
          },
          attrs: { height: "auto" }
        },
        [_vm._v(" ")]
      ),
      _c(
        "div",
        {
          staticClass: "voiceToText",
          style: {
            opacity: _vm.shrink ? "0" : "1"
          }
        },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: _vm.$t("voiceToText"),
                placement: "top-start"
              }
            },
            [_c("div", { staticClass: "voiceIcon" })]
          ),
          _c("el-switch", {
            on: { change: _vm.changeSpeechConvertStatus },
            model: {
              value: _vm.speechConvertStatus,
              callback: function($$v) {
                _vm.speechConvertStatus = $$v
              },
              expression: "speechConvertStatus"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "imelink-groupmember__view" }, [
        _vm.control.isMemberOfThisGroup
          ? _c(
              "div",
              { staticClass: "optionInterface" },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("searchChatHistory"),
                      placement: "bottom",
                      "visible-arrow": false,
                      "popper-class": "optionInterfaceTooltip_imelinkGroup"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "buttonStyle",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openInnerSearch.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont_Me icon-search iconStyle"
                        })
                      ]
                    )
                  ]
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: _vm.isGroupOwner
                        ? _vm.$t("transferOwner")
                        : _vm.$t("applyForGroupOwnerPermission"),
                      placement: "bottom",
                      "visible-arrow": false,
                      "popper-class": "optionInterfaceTooltip_imelinkGroup"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "buttonStyle",
                        staticStyle: { "margin-left": "14px" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changeOwnerPermission.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "22px", height: "22px" },
                          attrs: {
                            src: require("../../../assets/images/im/transfer_button.png"),
                            fit: "fill"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.groupOwner.id
          ? _c(
              "div",
              { staticClass: "ownerBlock" },
              [
                _c("div", { staticClass: "line" }),
                _c("imelink-contact", {
                  attrs: {
                    data: _vm.groupOwner,
                    "group-owner-id": _vm.groupOwner.id,
                    "name-size": "12px",
                    "avatar-size": "30px"
                  },
                  on: {
                    click: function($event) {
                      return _vm._handleChangeMessage(_vm.groupOwner)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "membersView" },
          _vm._l(_vm.groupInfo.members, function(member, index) {
            return _c("imelink-contact", {
              key: index,
              attrs: {
                data: member,
                "group-owner-id": _vm.groupInfo.ownerId,
                "name-size": "12px",
                "avatar-size": "30px",
                transferringOwner: _vm.transferringOwner,
                transferringOwnerSelect: member.transferringOwnerSelect
              },
              on: {
                click: function($event) {
                  return _vm._handleChangeMessage(member)
                },
                selectNewGroupOwner: _vm.selectNewGroupOwner
              },
              scopedSlots: _vm._u(
                [
                  _vm.control.user.id == _vm.groupInfo.ownerId &&
                  member.id !== _vm.control.user.id &&
                  !_vm.transferringOwner
                    ? {
                        key: "action",
                        fn: function() {
                          return [
                            _c("i", {
                              staticClass: "el-icon-error",
                              staticStyle: { color: "#F56C6C" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm._handleRemoveMember(member)
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            })
          }),
          1
        ),
        _vm.transferringOwner
          ? _c("div", { staticClass: "listFooter" }, [
              _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "common",
                  class: { confirmButton: _vm.newGroupOwner },
                  style: {
                    cursor: !_vm.newGroupOwner ? "not-allowed" : "pointer"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.startTransfer.apply(null, arguments)
                    }
                  }
                },
                [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "common cancelButton",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.changeTransfer.apply(null, arguments)
                    }
                  }
                },
                [_c("i", { staticClass: "iconfont_Me icon-x iconStyle" })]
              )
            ])
          : _vm._e()
      ]),
      _vm.showFooter
        ? _c(
            "el-footer",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.shrink,
                  expression: "!shrink"
                }
              ],
              staticClass: "imelink-footer imelink-groupmember__footer"
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.groupInfo.accessPattern != 7 ||
                      _vm.control.user.id === _vm.groupInfo.ownerId,
                    expression:
                      "\n        groupInfo.accessPattern != 7 || control.user.id === groupInfo.ownerId\n      "
                  }
                ],
                staticClass: "el-icon--plus",
                attrs: { title: _vm.$t("inviteFriend"), size: "small" },
                on: { click: _vm.handleInvite }
              }),
              !_vm.isCustomerServiceGroup
                ? _c("i", {
                    class: _vm.isGroupOwner
                      ? "iconfont_Me icon-delet"
                      : "el-icon--quit",
                    staticStyle: { "font-size": "18px" },
                    attrs: {
                      title: _vm.isGroupOwner
                        ? _vm.$t("deleteGroup")
                        : _vm.$t("quitGroup"),
                      size: "small"
                    },
                    on: { click: _vm.handleExit }
                  })
                : _vm._e(),
              !_vm.isCustomerServiceGroup
                ? _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.groupInfo.type == 3 ||
                          (_vm.groupInfo.type == 1 &&
                            _vm.groupInfo.accessPattern == 1),
                        expression:
                          "\n        groupInfo.type == 3 ||\n          (groupInfo.type == 1 && groupInfo.accessPattern == 1)\n      "
                      }
                    ],
                    staticClass: "el-icon-document-copy",
                    attrs: { title: _vm.$t("copyGroup"), size: "small" },
                    on: { click: _vm.handleCopy }
                  })
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c("ApplyForOwnerPermission", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          groupOwner: _vm.groupOwner,
          groupInfo: _vm.control.currentContact
        },
        on: {
          closeDialog: function($event) {
            _vm.dialogVisible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }