<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="310px"
    center
    :close-on-click-modal="false"
    :modal-append-to-body="true"
    :append-to-body="true"
    custom-class="applyForOwnerPermission"
    :top="'0'"
  >
    <div class="conentBox" v-if="type == 'default'">
      <div class="closeBox" @click.stop="dialogClose">
        <i class="icon-x iconfont_Me iconStyle"></i>
      </div>
      <div class="title">
        {{ $t("currentGroupOwner") }}
      </div>
      <div class="ownerInfo">
        <imelink-contact
          :data="groupOwner"
          name-size="16px"
          avatar-size="40px"
          :noHover="true"
          :notAllowClickAvatar="true"
          @click.stop="stopPropagation"
        >
        </imelink-contact>
      </div>
      <div class="footer">
        <div
          class="button"
          v-waves
          @click.stop="startApply"
          :title="$t('applyForGroupOwnerPermission')"
        >
          {{ $t("applyForGroupOwnerPermission") }}
        </div>
      </div>
    </div>
    <div class="conentBox" v-else-if="type == 'error'">
      <div class="closeBox" @click.stop="dialogClose">
        <i class="icon-x iconfont_Me iconStyle"></i>
      </div>
      <div class="title">
        {{ $t("notice") }}
      </div>
      <div class="textInfo">
        {{ $t("haveAppliedForGroupPermission") }}
      </div>
    </div>
  </el-dialog>
</template>
<script>
import ImelinkContact from "../../contact-item";
import * as IMApi from "@/api/im";
export default {
  components: {
    ImelinkContact,
  },
  data() {
    return {
      type: "default",
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    groupOwner: {
      type: Object,
      default: () => {
        return {};
      },
    },
    groupInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          this.type = "default";
        }
      },
      deep: true,
    },
  },
  methods: {
    dialogClose() {
      this.$emit("closeDialog");
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    async startApply() {
      if (!this.groupInfo.isGroup) {
        return;
      }
      const result = await IMApi.applyToBecomeAGroupLeader(
        this.groupInfo.id,
        this.groupOwner.id
      );
      if (result.code == 200) {
        this.$emit("closeDialog");
      } else if (result.code == 400) {
        this.type = "error";
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.applyForOwnerPermission
  .conentBox
    width: 100%;
    height: 100%;
    position: relative;
    .closeBox
      position: absolute;
      width: 24px;
      height: 24px;
      top: 9px;
      right: 12px;
      z-index: 2;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        font-size: 20px;
        color: #CFCFCF;
    .title
      cursor: auto;
      user-select: none;
      width: 100%;
      text-align: center;
      padding-top: 25px;
      font-size: 14px;
      color: #000000;
    .textInfo
      width: 100%;
      height: 80px;
      padding: 16px 15px 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      font-size: 14px;
    .ownerInfo
      width: 100%;
      height: 60px;
      padding: 16px 15px 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    .footer
      margin-top: 30px;
      width: 100%;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 15px;
      .button
        height: 100%;
        padding: 5px 15px;
        background: #52BD68;
        border-radius: 15px;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        overflow hidden;
        text-overflow: ellipsis;
        text-align: center;
</style>
<style lang="stylus">
.applyForOwnerPermission
  padding: 0;
  border-radius: 10px;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    min-height: 190px;
</style>
