var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "310px",
        center: "",
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        "append-to-body": true,
        "custom-class": "applyForOwnerPermission",
        top: "0"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.type == "default"
        ? _c("div", { staticClass: "conentBox" }, [
            _c(
              "div",
              {
                staticClass: "closeBox",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.dialogClose.apply(null, arguments)
                  }
                }
              },
              [_c("i", { staticClass: "icon-x iconfont_Me iconStyle" })]
            ),
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("currentGroupOwner")) + "\n    "
              )
            ]),
            _c(
              "div",
              { staticClass: "ownerInfo" },
              [
                _c("imelink-contact", {
                  attrs: {
                    data: _vm.groupOwner,
                    "name-size": "16px",
                    "avatar-size": "40px",
                    noHover: true,
                    notAllowClickAvatar: true
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.stopPropagation.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "footer" }, [
              _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "button",
                  attrs: { title: _vm.$t("applyForGroupOwnerPermission") },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.startApply.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("applyForGroupOwnerPermission")) +
                      "\n      "
                  )
                ]
              )
            ])
          ])
        : _vm.type == "error"
        ? _c("div", { staticClass: "conentBox" }, [
            _c(
              "div",
              {
                staticClass: "closeBox",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.dialogClose.apply(null, arguments)
                  }
                }
              },
              [_c("i", { staticClass: "icon-x iconfont_Me iconStyle" })]
            ),
            _c("div", { staticClass: "title" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("notice")) + "\n    ")
            ]),
            _c("div", { staticClass: "textInfo" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("haveAppliedForGroupPermission")) +
                  "\n    "
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }